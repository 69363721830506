import User from '@/models/User'
import Avatar from '@/models/Avatar'
import Api from '@/services/api'
import { get } from 'lodash'

export default {
  namespaced: true,
  state: {
    list: [],
    listMeta: {
      current_page: 0,
      last_page: 1,
    },
    userDetails: null,
    totalUsers: 0,
  },

  mutations: {
    setUserList(state, users) {
      state.list = users
    },

    clearUserList(state) {
      state.list = []
      state.totalUsers = 0
    },

    setUserListMeta(state, meta) {
      state.listMeta = meta
    },

    setUserDetails(state, user) {
      if (state.userDetails && !user.avatar) {
        user.avatar = state.userDetails.avatar
      }

      if (user instanceof User) {
        state.userDetails = user
      } else {
        state.userDetails = new User(user)
      }
    },

    setSelectedUserAvatar(state, avatar) {
      if (avatar instanceof Avatar) {
        state.userDetails.avatar = avatar
      } else {
        state.userDetails.avatar = new Avatar(avatar)
      }
    },

    setTotalUser(state, total) {
      state.totalUsers = total
    },

    clearUserDetails(state) {
      state.userDetails = null
    },

    userAvatarRemoved(state) {
      state.userDetails.avatar = null
    },
  },

  actions: {
    async getUsers({ commit, rootGetters }, params) {
      const query = User.page(params.page || 1)
        .include(['avatar', 'building', 'property'])
        .orderBy(params.sort)

      switch (params.filter) {
        case 'active':
          break
        case 'deleted':
          query.where('trashed', 'only')
          break
        case 'blocked':
          query.where('only_blocked', 1)
          break
        case 'all':
        default:
          query.where('trashed', 'with').where('with_blocked', 1)
          break
      }

      if (params.search) {
        query.where('search', encodeURIComponent(params.search))
      }

      let filteredBuilding = rootGetters['filter/buildingId']
      if (filteredBuilding) {
        query.where('building_id', filteredBuilding)
      }

      const res = await query.params({ limit: params.itemsPerPage || 10 }).get()

      commit('setUserList', res.data)
      commit('setUserListMeta', get(res, 'meta', {}))
      if (get(res, 'meta.total', 0) > 0) {
        commit('setTotalUser', get(res, 'meta.total', 0))
      }
    },

    async getUsersByProperty({ state }, params) {
      let requestUrl = `users`
      if (params.propertyId) {
        requestUrl += `?property_id=${params.propertyId}`
      }

      const { data } = await Api.get(requestUrl)
      return data.data
    },

    async getUserDetails({ commit }, id) {
      const { data } = await Api.get(`users/${id}`)
      commit('setUserDetails', data.data)
    },

    async changeAvatar({ commit }, formData) {
      const { data } = await Api.post(
        `users/${formData.get('id')}/avatar`,
        formData
      )
      commit('setSelectedUserAvatar', {
        ...data.data,
        thumb_url: formData.get('avatar_url'),
      })
    },

    async registerUser({ commit }, formData) {
      const { data } = await Api.post(`users`, formData)
      commit('setUserDetails', data.data)
    },

    async updateUser({ commit }, formData) {
      const { data } = await Api.put(`users/${formData.id}`, formData)
      commit('setUserDetails', data.data)
    },

    async blockUser({ commit }, { id, action }) {
      const { data } = await Api.post(`admin/users/${id}/${action}`)
      commit('setUserDetails', data.data)
    },

    async restoreUser({ commit }, formData) {
      const { data } = await Api.post(`users/${formData.id}/restore`)
      commit('setUserDetails', data.data)
    },

    async exportToExcel({ commit }, params) {
      const file = await Api.get(
        `/users/export${params && params.sort ? `?sort=${params.sort}` : ''}`,
        {
          responseType: 'blob',
        }
      )
      return file
    },
  },
}
